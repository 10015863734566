<template>
  <el-dialog
    :visible.sync="show"
    append-to-body
    width="1200px"
    v-dialogDrag
    top='0vh'
    :before-close="handleClose"
  >
    <div slot="title" class="custom-title">预览</div>
    <div class="detail-container" v-if="show" v-loading="changeLoading">
      <div class="left-part">
        <h3>素材</h3>
        <div class="source-item">
          <template v-if="copyData.materialType === 3">
            <div>
              <img :src="copyData.resources[curIndex].url" alt="" />
            </div>
          </template>
          <template v-else>
            <div v-if="[2].includes(copyData.materialType)">
              <img :src="copyData.resources[0].url" alt="" />
            </div>
            <div v-else>
              <video :src="copyData.resources[0].url" controls muted></video>
            </div>
          </template>
        </div>
        <div class="source-list">
          <template v-if="copyData.materialType === 3">
            <div
              v-for="(item, index) in copyData.resources"
              :key="item.id"
              @click="changeImage(index)"
            >
              <img :src="item.url" alt="" />
            </div>
            <div style="text-align: center">
              {{ copyData.resources.length }}个资源
            </div>
          </template>
          <template v-else>
            <div>
              <img
                v-if="[2].includes(copyData.materialType)"
                :src="copyData.resources[0].url"
              />
              <video v-else :src="copyData.resources[0].url"></video>
            </div>
            <div style="text-align: center">
              {{ copyData.resources.length }}个素材
            </div>
          </template>
        </div>
      </div>
      <div class="right-part">
        <div class="container">
          <div class="base-info part">
            <div class="title"><h3>基础信息</h3></div>
            <p>
              <span>名称</span>
              <span v-if="copyData.materialType === 3">{{ copyData.title }}</span>
              <span v-else>{{ copyData.resources[0].title }}</span>
            </p>
            <p>
              <span>素材类型</span>
              <span>{{
                copyData.materialType === 1
                  ? "视频"
                  : copyData.materialType === 2
                  ? "单图"
                  : " 组图"
              }}</span>
            </p>
            <p>
              <span>素材尺寸</span>
              <span v-if="copyData.materialType === 3">{{
                copyData.resources[curIndex].widthAndHigh
              }}</span>
              <span v-else>{{ copyData.resources[0].widthAndHigh }}</span>
            </p>
            <p>
              <span>素材格式</span>
              <span v-if="copyData.materialType === 3">{{
                copyData.resources[curIndex].format
              }}</span>
              <span v-else>{{ copyData.resources[0].format }}</span>
            </p>
            <p>
              <span>素材大小</span>
              <span v-if="copyData.materialType === 3">{{
                copyData.resources[curIndex].size
              }}</span>
              <span v-else>{{ copyData.resources[0].size }}</span>
            </p>
            <p style="margin-top: 40px">
              <span>上传人</span>
              <span>{{ copyData.adminUserName }}</span>
            </p>
            <p>
              <span>上传时间</span>
              <span>{{ copyData.createdTime }}</span>
            </p>
          </div>
          <div class="tags part">
            <template v-if="copyData.materialType === 3">
              <div class="title">
                <h3>
                  标签 &nbsp;&nbsp;
                  {{ copyData.tagItems.length }}个
                </h3>

                <div class="tag-list">
                  <span
                    v-for="(item, index) in copyData.tagItems"
                    :key="index"
                    >{{ item.name }}</span
                  >
                </div>
              </div>
            </template>
            <template v-else>
              <div class="title">
                <h3>
                  标签 &nbsp;&nbsp;
                  {{ copyData.tagItems.length }}个
                </h3>
                <div class="tag-list">
                    <span
                      v-for="item in copyData.tagItems"
                      :key="item.id"
                      type="info"
                      >{{ item.name }}</span
                    >
                </div>
              </div>
            </template>
          </div>
          <div class="document-text part">
            <template v-if="copyData.materialType === 3">
              <div class="title">
                <h3>文案 &nbsp;&nbsp; {{ copyData.documentWares.length }}条</h3>
                <div class="doc-list">
                    <p v-for="item in copyData.documentWares" :key="item.id">
                      {{ item.content }}
                    </p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="title">
                <h3>文案 &nbsp;&nbsp; {{ copyData.documentWares.length }}条</h3>
                <div class="doc-list">
                  <p v-for="item in copyData.documentWares" :key="item.id">
                    {{ item.content }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <i
      class="iconfont icon-zuo1 pre icon"
      v-if="showPreIcon"
      @click="preItem"
    ></i>
    <i
      class="iconfont icon-you next icon"
      v-if="showNextIcon"
      @click="nextItem"
    ></i>
  </el-dialog>
</template>

<script>
import { getMaterialDetail } from '@/api/material'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    curInfo: {
      type: Object,
      default: () => {}
    },
    dataList: {
      type: Array
    }
  },
  data () {
    return {
      partInfo: {},
      curIndex: 0,
      curIndex2: 0,
      dataIndex: 0,
      showNextIcon: false,
      showPreIcon: false,
      changeLoading: false,
      copyData: {}
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.renderData()
        }
      }
    },
    curInfo: {
      handler (newV) {
      }
    }
  },
  computed: {
  },
  mounted () {},
  methods: {
    renderData () {
      this.copyData = JSON.parse(JSON.stringify(this.curInfo))
      if (Array.isArray(this.dataList) && this.dataList.length > 0) {
        this.dataIndex = this.dataList.findIndex(
          (item) => item.id === this.curInfo.id
        )
        this.checkIcon()
      }
    },
    handleClose () {
      this.$emit('close')
    },
    checkIcon () {
      if (this.dataIndex + 1 === this.dataList.length) {
        this.showNextIcon = false
      } else {
        this.showNextIcon = true
      }
      if (this.dataIndex === 0) {
        this.showPreIcon = false
      } else {
        this.showPreIcon = true
      }
    },
    changeImage (index) {
      this.curIndex = index
    },
    preItem () {
      this.dataIndex--
      const id = this.dataList[this.dataIndex].id
      this.changeLoading = true
      getMaterialDetail(id)
        .then((res) => {
          this.copyData = res
          this.checkIcon()
        })
        .finally(() => {
          this.changeLoading = false
        })
    },
    nextItem () {
      this.dataIndex++
      const id = this.dataList[this.dataIndex].id
      this.changeLoading = true
      getMaterialDetail(id)
        .then((res) => {
          this.copyData = res
          this.checkIcon()
        })
        .finally(() => {
          this.changeLoading = false
        })
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
/deep/ .el-dialog__header {
  padding: 0;
  border-bottom: 1px solid #f4f1f4;
  .custom-title {
    padding: 20px 10px 20px 47px;
    font-size: 20px;
  }
}
/deep/ .el-dialog__title {
  font-size: 20px;
}
/deep/ .el-dialog__body {
  padding: 0;
}
h3 {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.detail-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .left-part {
    padding: 27px 43px;
    .source-item {
      padding: 25px;
      > div {
        border-radius: 17px;
        width: 390px;
        height: 600px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          text-align: center;
        }
        video {
          max-width: 100%;
          max-height: 600px;
          object-fit: cover;
        }
      }
    }
    .source-list {
      padding-left: 25px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      > div {
        height: 51px;
        width: 83px;
        // border: 1px dashed #ebebeb;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 10px;
        line-height: 51px;
        background: #f4f4f4;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        video {
          width: 100%;
          max-height: 50px;
          object-fit: cover;
        }
        &:not(:nth-last-of-type(1)) {
          cursor: pointer;
        }
      }
    }
  }
  .right-part {
    border-left: 8px solid #fbfbfb;
    flex-grow: 1;
    min-width:500px;
    .container {
      height: 905px;
      .part {
        padding: 0 39px;
        .title {
          padding-top: 27px;
        }
        &:not(:nth-last-of-type(1)) {
          border-bottom: 8px solid #fbfbfb;
        }
      }

      .base-info {
        p {
          margin-bottom: 12px;
          font-size: 18px;
          display: flex;
          justify-content: flex-start;
          > span:nth-of-type(1) {
            color: #959595;
            width: 100px;
          }
          > span:nth-of-type(2) {
            color: #000;
            font-weight: bold;
          }
        }
        p:nth-of-type(1) {
          margin-top: 26px;
        }
        p:nth-last-of-type(1) {
          margin-bottom: 30px;
        }
      }
      .tags {
        .tag-list {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 23px;
          height:100px;
          overflow:auto;
          span {
            height: 32px;
            line-height: 32px;
            padding: 0 12px;
            border: 1px solid #ddd;
            margin: 0 10px 10px 0;
            border-radius: 8px;
          }
        }
      }
      .document-text {
        .doc-list {
          margin-top: 10px;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          height:200px;
          overflow:auto;

          p {
            padding: 10px;
            background: #f4f1f4;
            border-radius: 4px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.icon {
  position: absolute;
  color: #ccc;
  font-size: 40px;
  font-weight: bold;
  z-index: 9999;
  transform: translateX(-50%);
  cursor: pointer;
}
.pre {
  top: 50%;
  left: 1%;
}

.next {
  left: 99%;
  top: 50%;
}
</style>
