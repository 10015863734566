<template>
  <el-dialog
    :before-close="handleClose"
    :visible.sync="show"
    append-to-body
    v-dialogDrag
    width="1200px"
    top="2vh"
  >
    <div slot="title" class="custom-title">选择文案</div>
    <div class="container flex-row-start-start">
      <div class="left">
        <div class="title title-info flex-row-start-start">
          <div>
            <el-checkbox
              v-model="checkAll"
              class="margin-right-ten"
              @change="handleCheckAll"
            ></el-checkbox>
            <span>选择文案</span>
            <c-input
              style="width: 300px; margin-left: 20px"
              v-model="search"
              placeholder="文案内容"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchDoc"
              ></el-button>
            </c-input>
          </div>
          <span>共{{ docList.length }}条文案</span>
        </div>
        <div class="doc-container">
          <div class="doc-item" v-for="item in docList" :key="item.id">
            <label class="label-item">
              <el-checkbox
                v-model="item.checkStatus"
                @click.native="handleCheckBoxClick($event, item)"
              ></el-checkbox>
              <span style="margin-left: 10px; line-height: 1.5">
                {{ item.content }}
              </span>
            </label>
          </div>
        </div>
        <div class="add-item">
          <el-input
            type="textarea"
            v-model="extralContent"
            style="width: 400px"
            placeholder="请输入文案"
          ></el-input>
          <el-button type="primary" class="margin-left-ten" @click="addDoc"
            >添加</el-button
          >
        </div>
      </div>
      <div class="right">
        <div class="title">
          <span
            >已选择
            <span style="color: #00bf8a">{{ alreadyChooseDoc.length }} </span
            >个文案</span
          >
        </div>
        <div class="doc-list">
          <p
            v-for="(item, index) in alreadyChooseDoc"
            :key="item.id"
            @click="deleteChooseItem(index)"
          >
            {{ item.content }}
          </p>
        </div>
      </div>
    </div>
    <div class="button-list">
      <el-button type="primary" @click="handleOk">确定</el-button>
      <el-button @click="handleClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getDocWareList, addDocWareItem } from "@/api/material";
export default {
  props: ["show", "curInfo", "limit"],
  data() {
    return {
      checkAll: false,
      docList: [],
      copyDocList: [],
      extralContent: "",
      search: "",
      alreadyChooseDoc: [],
    };
  },
  mounted() {},
  watch: {
    show: {
      async handler(newV) {
        if (newV) {
          await this.echoData();
          await this.renderData();
        }
      },
    },
  },
  methods: {
    renderData() {
      return new Promise((resolve) => {
        getDocWareList({
          pageSize: 999,
        }).then((res) => {
          res.list.forEach((item) => {
            if (this.alreadyChooseDoc.find((key) => key.id === item.id)) {
              item.checkStatus = true;
            } else {
              item.checkStatus = false;
            }
          });
          this.docList = res.list;
          this.copyDocList = JSON.parse(JSON.stringify(res.list));
          resolve();
        });
      });
    },
    echoData() {
      return new Promise((resolve) => {
        if (this.curInfo.documentWareIds) {
          this.alreadyChooseDoc = this.curInfo.documentWareIds;
        }
        resolve();
      });
    },
    handleOk() {
      if (typeof this.limit === "number") {
        if (this.alreadyChooseDoc.length > this.limit) {
          return this.$message.error("总文案条数不能大于十条");
        }
        console.log(this.alreadyChooseDoc, "alreadyChooseDoc");
      }
      console.log(this.limit, "limitNumber");
      this.$emit("success", this.alreadyChooseDoc);
      this.handleClose();
    },
    handleClose() {
      this.alreadyChooseDoc = [];
      this.extralContent = "";
      this.$emit("close");
    },
    deleteChooseItem(index) {
      const item = this.alreadyChooseDoc.splice(index, 1)[0];
      item.checkStatus = false;
      const targetIndex = this.docList.findIndex((key) => key.id === item.id);
      this.$set(this.docList, targetIndex, item);
    },
    handleCheckBoxClick($event, val) {
      if ($event.target.tagName !== "INPUT") return;
      this.$nextTick(() => {
        this.handleChange(val);
      });
    },
    handleChange(val) {
      setTimeout(() => {
        if (val.checkStatus) {
          this.alreadyChooseDoc.push(val);
        } else {
          const targetIndex = this.alreadyChooseDoc.findIndex(
            (item) => item.id === val.id
          );
          this.alreadyChooseDoc.splice(targetIndex, 1);
        }
      });
    },
    handleCheckAll(val) {
      this.alreadyChooseDoc = val
        ? JSON.parse(JSON.stringify(this.docList))
        : [];
      this.docList.forEach((item) => {
        item.checkStatus = !!val;
      });
    },
    addDoc() {
      if (!this.extralContent) {
        return this.$message.warning("文案内容不能为空！");
      }
      addDocWareItem(this.extralContent).then(() => {
        this.$message.success("新增成功！");
        this.extralContent = "";
        this.renderData();
      });
    },
    searchDoc() {
      let arr = [];
      if (this.search) {
        arr = JSON.parse(
          JSON.stringify(
            this.copyDocList.filter((item) =>
              item.content.includes(this.search)
            )
          )
        );
      } else {
        arr = JSON.parse(JSON.stringify(this.copyDocList));
      }
      arr.forEach((item) => {
        if (this.alreadyChooseDoc.findIndex((i) => i.id === item.id) > -1) {
          item.checkStatus = true;
        }
      });
      this.docList = arr;
    },
  },
  components: {},
};
</script>
<style lang='scss' scoped>
/deep/ .el-dialog__header {
  padding: 0;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e4e4e4;
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-checkbox {
  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    &:after {
    }
  }
}
.custom-title {
  text-align: left;
  padding: 0 25px;
  font-size: 20px;
}
.left {
  width: 60% !important;
  padding: 25px;
  padding-right: 20px;
  border-right: 1px solid #e4e4e4;
  position: relative;
  .doc-container {
    max-height: 600px;
    overflow: auto;
    position: relative;
    .doc-item {
      margin: 20px 0;
      transition: color 0.2s;
      &:nth-of-type(1) {
        margin-top: 0 !important;
      }
      &:hover {
        color: #00bf8a;
      }
      > span {
        margin-left: 10px;
        line-height: 2;
      }
    }
  }
  .add-item {
    padding-top: 20px;
  }
}
.right {
  flex-grow: 1;
  padding: 25px;

  .doc-list {
    margin-top: 20px;
    max-height: 600px;
    overflow: auto;
    p {
      margin: 20px 0;
      border-radius: 8px;
      padding: 10px;
      background: #f2f2f2;
      font-size: 14px;
      line-height: 2;
      position: relative;
      width: 385px;
      transition: all 0.3s;
      cursor: pointer;
      .del-icon {
        display: none;
        position: absolute;
        left: 90%;
        top: -10%;
        background: #ccc;
      }
      &:hover {
        display: block;
        background: #fd5d5a;
        color: #fff;
      }
    }
  }
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.title-info {
  margin-bottom: 25px;
  > span {
    margin-left: auto;
  }
}
.button-list {
  margin-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.label-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>
