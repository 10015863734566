<template>
  <el-dialog
    :close-on-click-modal="false"
    :before-close="handleClose"
    :visible.sync="show"
    append-to-body
    width="90%"
    center
  >
    <div slot="title" class="custom-title">
      {{ isEdit ? "编辑" : "新增" }}素材
    </div>
    <div class="container" v-if="show">
      <template v-if="!isEdit">
        <div class="choose-type">
          <el-button type="info">视频</el-button>
          <el-button disabled>图片</el-button>
        </div>
        <div class="choose-pic-type">
          <el-button type="primary" @click="uploadSinglePic"
            >上传单张图片</el-button
          >
          <el-button
            v-if="canUploadBatchPic"
            type="warning"
            @click="uploadGroupPic"
            >上传图片组</el-button
          >
          <span style="margin-left: 50px" v-if="canUploadBatchPic">
            图片尺寸:800 * 800,960 * 540,支持JPG、PNG,
            <span class="highlint-red">组图最多不超过9张</span>
          </span>

          <input
            type="file"
            ref="singleUpload"
            @change="fileChangeSingle"
            multiple
            style="display: none"
          />
          <input
            type="file"
            ref="groupUpload"
            @change="fileChangeGroup"
            multiple
            style="display: none"
          />
        </div>
      </template>
      <div
        class="upload-image-single"
        v-if="type === 2"
        v-loading="singleLoading"
      >
        <div v-for="(data, index) in dataCollection.dataList" :key="index">
          <template v-if="index + 1 <= dataCollection.dataList.length">
            <c-table
              :dataList="data"
              :rowList="rowList"
              :border="true"
              :isShowPagination="false"
              :loading="imageSingleLoading"
              operationWidth="200"
            >
              <template #tagItemIdsHeader>
                <span>
                  标签
                  {{ data[0].tagItemIds ? data[0].tagItemIds.length : 0 }}个
                </span>
              </template>
              <template #documentWareIdsHeader>
                <span>
                  文案
                  {{
                    data[0].documentWareIds
                      ? data[0].documentWareIds.length
                      : 0
                  }}个
                </span>
              </template>
              <template #title="{ row }">
                <div class="title">
                  <span v-if="!row.isEditTitle">{{ row.title }}</span>
                  <el-input v-else v-model="row.title" placeholder="请输入标题">
                    <el-button
                      slot="append"
                      icon="el-icon-circle-check"
                      @click="changeTitle(row)"
                    ></el-button>
                  </el-input>
                  <i
                    style="margin-left: 10px; font-size: 18px; color: #00bf8a"
                    class="el-icon-edit"
                    v-if="!row.isEditTitle"
                    @click="row.isEditTitle = true"
                  ></i>
                </div>
              </template>
              <template #tagItemIds="{ row }">
                <div class="flex-row-start-start">
                  <div class="tags-list">
                    <template v-if="getStatus(row)">
                      <div
                        class="tag-item"
                        v-for="(item, index) in row.tagItemIds"
                        :key="index"
                      >
                        <template
                          v-if="
                            Array.isArray(item.checkedTagList) &&
                            item.checkedTagList.length > 0
                          "
                        ></template>
                        <p>{{ item.groupName }}</p>
                        <div class="flex-row-start-start-wrap">
                          <div
                            class="tag-list"
                            v-for="(tag, index2) in item.checkedTagList"
                            :key="tag.id"
                          >
                            <el-tag type="info" size="mini">
                              {{ tag.name }}
                            </el-tag>
                            <i
                              class="iconfont icon-shanchu1 del-item"
                              @click.stop="
                                ($event) => {
                                  delTag(
                                    item.checkedTagList,
                                    index2,
                                    index,
                                    row
                                  );
                                }
                              "
                            ></i>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <el-button
                        class="button-small"
                        size="small"
                        type="primary"
                        icon="el-icon-plus"
                        @click="addTag(row)"
                        >新增标签</el-button
                      >
                    </template>
                  </div>
                  <i
                    v-if="getStatus(row)"
                    class="el-icon-circle-plus add-icon"
                    style="color: #00bf85; cursor: pointer"
                    @click="
                      ($event) => {
                        addTag(row);
                      }
                    "
                  ></i>
                </div>
              </template>
              <template #documentWareIds="{ row }">
                <div class="doc-list">
                  <template
                    v-if="
                      Array.isArray(row.documentWareIds) &&
                      row.documentWareIds.length > 0
                    "
                  >
                    <div
                      v-for="(item, index) in row.documentWareIds"
                      :key="item.id"
                      class="doc-item"
                    >
                      <p>{{ item.content }}</p>
                      <span style="margin-left: 5px">
                        <i
                          class="el-icon-remove"
                          style="color: #ccc; margin-right: 5px"
                          @click="
                            ($event) => {
                              removeDoc(row, index);
                            }
                          "
                        ></i>
                        <i
                          class="el-icon-circle-plus"
                          v-if="index + 1 === row.documentWareIds.length"
                          style="color: #00bf8a"
                          @click="
                            ($event) => {
                              addDoc(row);
                            }
                          "
                        ></i>
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <el-button
                      class="button-small"
                      size="small"
                      icon="el-icon-plus"
                      @click="addDoc(row)"
                      >新增文案</el-button
                    >
                  </template>
                </div>
              </template>
              <template #buttonOperation="{ row }">
                <el-button
                  type="primary"
                  @click="previewItem(row, 'single')"
                  size="small"
                  class="button-small"
                  >预览</el-button
                >
                <el-button
                  type="danger"
                  @click="delItem(row)"
                  size="small"
                  class="button-small"
                  >删除</el-button
                >
              </template>
            </c-table>
          </template>
        </div>
      </div>
      <div class="upload-image-group" v-if="type === 3">
        <c-table
          :dataList="groupDataList"
          :rowList="rowList2"
          :border="true"
          :mergeCells="true"
          :mergeCellInfo="mergeCellInfo"
          :isShowPagination="false"
          operationWidth="200"
        >
          <template #tagHeader>
            <span>
              标签
              {{
                groupTagsList.reduce(
                  (acc, b, c) => acc + b.checkedTagList.length,
                  0
                )
              }}个
            </span>
          </template>
          <template #docHeader>
            <span>文案 {{ groupDocList.length }}个</span>
          </template>
          <template #title="{ row }">
            <div class="title" style="position: absolute; top: 0">
              <span v-if="!isEditTitle">{{ groupTitle }}</span>
              <el-input v-else v-model="groupTitle" placeholder="请输入标题">
                <el-button
                  slot="append"
                  icon="el-icon-circle-check"
                  @click="changeTitle(row, 'group')"
                ></el-button>
              </el-input>
              <i
                style="margin-left: 10px; font-size: 18px; color: #00bf8a"
                class="el-icon-edit"
                v-if="!isEditTitle"
                @click="isEditTitle = true"
              ></i>
            </div>
          </template>
          <template #tag="{ row }">
            <div
              class="flex-row-start-start"
              style="position: absolute; top: 0"
            >
              <div class="tags-list" ref="tagList">
                <template v-if="groupTagsList.length > 0">
                  <div
                    class="tag-item"
                    v-for="(item, index) in groupTagsList"
                    :key="index"
                  >
                    <template
                      v-if="
                        Array.isArray(item.checkedTagList) &&
                        item.checkedTagList.length > 0
                      "
                    >
                      <p>{{ item.groupName }}</p>
                      <div class="flex-row-start-start-wrap">
                        <div
                          class="tag-list"
                          v-for="(tag, index2) in item.checkedTagList"
                          :key="tag.id"
                        >
                          <el-tag type="info" size="mini">{{
                            tag.name
                          }}</el-tag>
                          <i
                            class="iconfont icon-shanchu1 del-item"
                            @click="
                              delGroupTag(item.checkedTagList, index2, index)
                            "
                          ></i>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <el-button
                    type="primary"
                    @click="addGroupTag"
                    class="button-small"
                    icon="el-icon-plus"
                    size="small"
                    >添加标签</el-button
                  >
                </template>
              </div>
              <i
                v-if="groupTagsList.length > 0"
                class="el-icon-circle-plus"
                style="color: #00bf85; cursor: pointer; margin-top: 14px"
                @click="
                  ($event) => {
                    addGroupTag();
                  }
                "
              ></i>
            </div>
          </template>
          <template #doc="{ row }">
            <div
              class="doc-list"
              ref="docList"
              style="position: absolute; top: 0"
            >
              <template v-if="groupDocList.length > 0">
                <div
                  class="doc-item"
                  v-for="(item, index) in groupDocList"
                  :key="item.id"
                >
                  <p>{{ item.content }}</p>
                  <span style="margin-left: 5px">
                    <i
                      class="el-icon-remove"
                      style="color: #ccc; margin-right: 5px"
                      @click="
                        ($event) => {
                          removeGroupDoc(index);
                        }
                      "
                    ></i>
                    <i
                      class="el-icon-circle-plus"
                      v-if="index + 1 === groupDocList.length"
                      style="color: #00bf8a"
                      @click="
                        ($event) => {
                          addGroupDoc();
                        }
                      "
                    ></i>
                  </span>
                </div>
              </template>
              <template v-else>
                <el-button
                  @click="addGroupDoc"
                  class="button-small"
                  icon="el-icon-plus"
                  size="small"
                  >添加文案</el-button
                >
              </template>
            </div>
          </template>
          <template #buttonOperation="{ row }">
            <div style="position: absolute; top: 0">
              <el-button
                type="primary"
                @click="previewItem(row, 'group')"
                size="small"
                class="button-small"
                >预览</el-button
              >
              <el-button
                type="danger"
                @click="delGroup(row)"
                size="small"
                class="button-small"
                >删除</el-button
              >
            </div>
          </template>
        </c-table>
      </div>
    </div>
    <div class="button-list">
      <el-button type="primary" @click="handleOk">确定</el-button>
      <el-button @click="handleClose">取消</el-button>
    </div>
    <add-doc
      :show="showAddDocDialog"
      :curInfo="curInfo"
      @close="showAddDocDialog = false"
      @success="handleDocSuccess"
    ></add-doc>
    <add-tag
      :show="showAddTagDialog"
      :curInfo="curInfo"
      :info="tagsList"
      @close="showAddTagDialog = false"
      @success="handleSuccess"
    ></add-tag>
    <preview-item
      :show="showDialog"
      :curInfo="curInfo"
      @close="showDialog = false"
    ></preview-item>
  </el-dialog>
</template>

<script>
import {
  getTagGroupList,
  addMaterialWare,
  editMaterialWare,
} from "@/api/material.js";
import PreviewItem from "./preview.vue";
import AddTag from "./add-tag.vue"; // 一个小时
import AddDoc from "./add-doc.vue";
import { uploadFile } from "@/api/common.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    echoData: {
      type: Object,
    },
    canUploadBatchPic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      doc: "",
      typeId: null,
      curInfo: null,
      type: null,
      isEdit: false, // 是否是从编辑按钮进入
      showDialog: false,
      dragover: false,
      isEditTitle: false,
      showAddTagDialog: false,
      showAddDocDialog: false,
      dataCollection: {
        dataList: [],
      },
      groupTitle: "",
      groupTagsList: [],
      groupDocList: [],
      groupDataList: [],
      tagsList: [],
      dataList: [],
      imageSingleLoading: false,
      singleLoading: false,
      mappingIds: {
        2: {
          en: "booksTypeIds",
          cn: "书籍类型",
        },
        3: {
          en: "videoTypeIds",
          cn: "视频类型",
        },
        4: {
          en: "imageTypeIds",
          cn: "图片类型",
        },
        5: {
          en: "putChannelIds",
          cn: "投放渠道",
        },
        6: {
          en: "booksNameIds",
          cn: "书籍名称",
        },
      },
      rowList: [
        {
          label: "图片",
          prop: "url",
          popover: true,
          mediaType: "image",
        },
        {
          label: "标题",
          prop: "title",
          slot: true,
          width: "150",
        },
        {
          label: "图片尺寸",
          prop: "widthAndHigh",
          width: "80",
        },
        {
          label: "图片格式",
          prop: "format",
          width: "80",
        },
        {
          label: "图片大小",
          prop: "size",
          width: "80",
        },
        {
          label: "标签",
          prop: "tagItemIds",
          slot: true,
          slotHeader: true,
          width: 200,
        },
        {
          label: "文案",
          prop: "documentWareIds",
          slot: true,
          slotHeader: true,
          width: "300",
        },
      ],
      rowList2: [
        {
          label: "图片",
          prop: "url",
          popover: true,
          mediaType: "image",
        },
        {
          label: "标题",
          prop: "title",
          slot: true,
          width: "150",
        },
        {
          label: "图片尺寸",
          prop: "widthAndHigh",
          width: "80",
        },
        {
          label: "图片格式",
          prop: "format",
          width: "80",
        },
        {
          label: "图片大小",
          prop: "size",
          width: "80",
        },
        {
          label: "标签",
          prop: "tag",
          slot: true,
          slotHeader: true,
          width: 200,
        },
        {
          label: "文案",
          prop: "doc",
          slot: true,
          slotHeader: true,
          width: "300",
        },
      ],
    };
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          getTagGroupList({
            pageSize: 999,
          }).then((res) => {
            this.tagsList = res.list;
            if (this.echoData) {
              // this.getTagsList()
              this.renderEchoData();
            }
          });
        }
      },
    },
    typeId: {
      handler(newV) {
        if (newV === 1) {
          this.uploadSinglePic();
        }
        if (newV === 2) {
          this.uploadGroupPic();
        }
      },
    },
  },
  computed: {
    mergeCellInfo() {
      return {
        title: {
          rowspan: this.groupDataList.length,
          colspan: 1,
        },
        tag: {
          rowspan: this.groupDataList.length,
          colspan: 1,
        },
        doc: {
          rowspan: this.groupDataList.length,
          colspan: 1,
        },
      };
    },
  },
  methods: {
    renderEchoData() {
      this.isEdit = true;
      const val = this.echoData.resources[0];
      const alreadyChooseTags = [];
      this.echoData.tagItems.forEach((tag) => {
        const targetIndex = alreadyChooseTags.findIndex(
          (item) => item.groupId === tag.tagGroupId
        );
        if (targetIndex === -1) {
          alreadyChooseTags.push({
            groupId: tag.tagGroupId,
            groupName: this.tagsList.find((item) => item.id === tag.tagGroupId)
              .tagGroupName,
            checkedTagList: [tag],
          });
        } else {
          alreadyChooseTags[targetIndex] = {
            ...alreadyChooseTags[targetIndex],
            checkedTagList: [
              ...alreadyChooseTags[targetIndex].checkedTagList,
              tag,
            ],
          };
        }
      });
      if (this.echoData.materialType === 2) {
        this.type = 2;
        const dataList = [
          {
            url: val.url,
            id: val.id,
            size: val.size,
            sort: val.sort,
            title: this.echoData.title,
            widthAndHigh: val.widthAndHigh,
            format: val.format,
            // tagItemIds: this.echoData.tagItems,
            tagItemIds: alreadyChooseTags,
            documentWareIds: this.echoData.documentWares,
            isEditTitle: false,
          },
        ];
        this.$set(this.dataCollection, "dataList", [dataList]);
      }
      if (this.echoData.materialType === 3) {
        this.type = 3;
        const { documentWares, resources, tagItems, title } = this.echoData;

        resources.forEach((item) => {
          this.groupDataList.push(item);
        });
        // this.groupTagsList = tagItems
        this.groupTagsList = alreadyChooseTags;
        this.groupDocList = documentWares;
        this.groupTitle = title;
      }
    },
    uploadSinglePic() {
      this.$refs.singleUpload.click();
      this.type = 2;
    },
    uploadGroupPic() {
      this.$refs.groupUpload.click();
      this.type = 3;
    },
    fileChangeSingle(e) {
      return new Promise(async (resolve) => {
        this.imageSingleLoading = true;
        const targetFiles = e.target.files;
        const arr = [];
        this.singleLoading = true;
        try {
          for (let i = 0; i < targetFiles.length; i++) {
            const file = targetFiles[i];
            const res = await this.formatUpload(file);
            arr.push([res]);
          }
        } catch (e) {
          console.log(e);
        } finally {
          this.singleLoading = false;
        }
        const arrOne = JSON.parse(JSON.stringify(this.dataCollection.dataList));
        arrOne.push(...arr);
        this.$set(this.dataCollection, "dataList", arrOne);
        this.imageSingleLoading = false;
        resolve(arr);
      });
    },
    fileChangeGroup(e) {
      // const targetFiles = e.target.files
      return new Promise(async (resolve) => {
        const targetFiles = e.target.files;
        const arr = [];
        for (let i = 0; i < targetFiles.length; i++) {
          const file = targetFiles[i];
          const res = await this.formatUpload(file);
          arr.push(res);
        }
        if (this.type === 3) {
          this.groupTitle = arr[0].title;
        }
        this.groupDataList = arr;
      });
    },
    formatUpload(file) {
      return new Promise((resolve) => {
        const type = file.name.split(/\.+/g);
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = (event) => {
          const url = event.currentTarget.result;
          const image = new Image();
          image.src = url;
          image.onload = async () => {
            const { width, height } = image;
            uploadFile({ file }).then((res) => {
              // if(this.type === 2){

              resolve({
                url: res,
                widthAndHigh: `${width} * ${height}`,
                size: `${Math.floor(file.size / 1024)}KB`,
                format: type[type.length - 1],
                tagItemIds: null,
                documentWareIds: null,
                title: file.name,
                materialType: this.type,
                isEditTitle: false,
              });
            });
          };
        };
      });
    },
    handleOk() {
      const mappingIds = {
        2: {
          en: "booksTypeIds",
          cn: "书籍类型",
        },
        3: {
          en: "videoTypeIds",
          cn: "视频类型",
        },
        4: {
          en: "imageTypeIds",
          cn: "图片类型",
        },
        5: {
          en: "putChannelIds",
          cn: "投放渠道",
        },
        6: {
          en: "booksNameIds",
          cn: "书籍名称",
        },
      };
      if (this.type === 2) {
        const { dataList } = this.dataCollection;
        let arr = [];
        if (dataList.length === 0) return this.$message.warning("请上传图片");
        dataList.forEach((item) => {
          const res = this.takeParams(item, 2, "add", mappingIds);
          arr = [...arr, res];
        });
        if (!this.isEdit) {
          addMaterialWare(arr).then((res) => {
            this.$message.success("新增成功!");
            this.handleClose();
            this.refreshData();
            this.transformData(res);
          });
        } else {
          const params = this.takeParams(dataList[0], 2, "edit", mappingIds);
          editMaterialWare(this.echoData.id, params).then(() => {
            this.$message.success("修改成功!");
            this.handleClose();
            this.refreshData();
          });
        }
      }
      if (this.type === 3) {
        if (this.groupDataList.length === 0) {
          return this.$message.warning("请上传素材！");
        }
        const arr = [];
        const params = this.takeParams(null, 3, null, mappingIds);
        this.groupDataList.forEach((item) => {
          arr.push({
            title: this.groupTitle,
            widthAndHigh: item.widthAndHigh,
            format: item.format,
            url: item.url,
            sort: 1,
            size: item.size,
          });
        });
        if (!this.isEdit) {
          params.materialResources = arr;
          addMaterialWare([params]).then(() => {
            this.$message.success("新增成功!");
            this.handleClose();
            // if (this.type !== 3) {
            this.refreshData();
            // }
          });
        } else {
          editMaterialWare(this.echoData.id, params).then((res) => {
            this.$message.success("修改成功！");
            this.handleClose();
            // if (this.type !== 3) {
            this.refreshData();
            // }
          });
        }
      }
    },
    takeParams(val, materialType = 2, type = "add", mappingIds) {
      let params = {};
      if (materialType === 2) {
        if (val[0].tagItemIds !== undefined && val[0].tagItemIds !== null) {
          val[0].tagItemIds.forEach((item, index) => {
            const { groupId, checkedTagList } = item;
            const pName = mappingIds[groupId].en;
            if (checkedTagList.length > 0) {
              params[pName] = checkedTagList.map((item) => item.id);
            }
          });
        }
        params.materialType = materialType;
        params.title = val[0].title;
        params.documentWareIds = val[0].documentWareIds
          ? val[0].documentWareIds.map((i) => i.id)
          : null;
        if (type === "add") {
          params.materialResources = [
            {
              title: val[0].title,
              widthAndHigh: val[0].widthAndHigh,
              format: val[0].format,
              size: val[0].size,
              url: val[0].url,
              sort: 1,
            },
          ];
        }
        if (type === "edit") {
          params.materialResources = [
            {
              id: val[0].id,
              title: val[0].title,
            },
          ];
        }
      }
      if (materialType === 3) {
        params = {
          materialType: 3,
          title: this.groupTitle,
          documentWareIds:
            this.groupDocList.length > 0
              ? this.groupDocList.map((item) => item.id)
              : null,
        };
        this.groupTagsList.forEach((item) => {
          const { groupId, checkedTagList } = item;
          const pName = mappingIds[groupId].en;
          if (checkedTagList.length > 0) {
            params[pName] = checkedTagList.map((item) => item.id);
          }
        });
      }
      return params;
    },
    transformData(res) {
      this.$emit("transformData", res);
    },
    refreshData() {
      this.$emit("freshData", {
        materialType: 2,
        id: this.echoData ? this.echoData.id : null,
      });
    },
    changeTitle(row, type) {
      if (type === "group") {
        this.isEditTitle = false;
      } else {
        row.isEditTitle = false;
      }
    },
    previewItem(row, types) {
      if (types === "single") {
        const obj = {
          materialType: 2,
          resources: [
            {
              ...row,
            },
          ],
          tagItems:
            Array.isArray(row.tagItemIds) && row.tagItemIds.length > 0
              ? row.tagItemIds
              : [],
          documentWares:
            Array.isArray(row.documentWareIds) && row.documentWareIds.length > 0
              ? row.documentWareIds
              : [],
        };
        this.curInfo = obj;
      }
      if (types === "group") {
        const arr = [];
        this.groupDataList.forEach((item) => {
          arr.push(item);
        });
        const obj = {
          materialType: 3,
          tagItems: this.groupTagsList,
          documentWares: this.groupDocList,
          resources: arr,
        };
        this.curInfo = obj;
      }
      this.showDialog = true;
    },
    delItem(row) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const targetIndex = this.dataCollection.dataList.findIndex(
            (item) => item[0].url === row.url
          );
          this.dataCollection.dataList.splice(targetIndex, 1);
        })
        .catch(() => {});
    },
    handleClose() {
      this.dataCollection = {
        dataList: [],
      };
      this.groupDataList = [];
      this.curIndex = 0;
      this.groupTitle = "";
      this.groupTagsList = [];
      this.groupDocList = [];
      this.isEdit = false;
      this.isEditTitle = false;
      this.$emit("close");
    },
    addGroupTag() {
      this.curInfo = {};
      this.$set(
        this.curInfo,
        "tagItemIds",
        JSON.parse(JSON.stringify(this.groupTagsList))
      );
      this.showAddTagDialog = true;
    },
    addTag(row) {
      getTagGroupList({
        pageSize: 999,
      }).then((res) => {
        this.tagsList = res.list;
        this.curInfo = JSON.parse(JSON.stringify(row));
        this.showAddTagDialog = true;
      });
    },
    delTag(val, index2) {
      val.splice(index2, 1);
    },
    addDoc(row) {
      this.curInfo = JSON.parse(JSON.stringify(row));
      this.showAddDocDialog = true;
    },
    addGroupDoc() {
      this.curInfo = {};
      this.$set(
        this.curInfo,
        "documentWareIds",
        JSON.parse(JSON.stringify(this.groupDocList))
      );
      this.showAddDocDialog = true;
    },
    handleSuccess(val) {
      if (this.type === 2) {
        const dataArr = this.dataCollection.dataList;
        const targetItem = dataArr.find(
          (item) => item[0].url === this.curInfo.url
        );
        const targetIndex = dataArr.findIndex(
          (item) => item[0].url === this.curInfo.url
        );
        targetItem[0].tagItemIds = JSON.parse(JSON.stringify(val));
        dataArr[targetIndex] = targetItem;
      } else {
        this.groupTagsList = JSON.parse(JSON.stringify(val));
      }
    },
    handleDocSuccess(val) {
      if (this.type === 2) {
        const dataArr = this.dataCollection.dataList;
        const targetItem = dataArr.find(
          (item) => item[0].url === this.curInfo.url
        );
        const targetIndex = dataArr.findIndex(
          (item) => item[0].url === this.curInfo.url
        );
        targetItem[0].documentWareIds = JSON.parse(JSON.stringify(val));
        dataArr[targetIndex] = targetItem;
      } else {
        this.groupDocList = JSON.parse(JSON.stringify(val));
      }
    },
    getTagsList() {
      const alreadyChooseTags = [];
      JSON.parse(JSON.stringify(this.groupTagsList)).forEach((tag) => {
        const targetIndex = alreadyChooseTags.findIndex(
          (item) => item.groupId === tag.tagGroupId
        );
        if (targetIndex === -1) {
          alreadyChooseTags.push({
            groupId: tag.tagGroupId,
            groupName: this.tagsList.find((item) => item.id === tag.tagGroupId)
              .tagGroupName,
            checkedTagList: [tag],
          });
        } else {
          const { checkedTagList } = alreadyChooseTags[targetIndex];
          alreadyChooseTags[targetIndex] = {
            ...alreadyChooseTags[targetIndex],
            checkedTagList: [...checkedTagList, tag],
          };
        }
      });
      this.groupTagsList = alreadyChooseTags;
    },
    delGroup() {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(() => {
        this.groupDataList = [];
        this.groupDocList = [];
        this.groupTitle = "";
        this.groupTagsList = [];
      });
    },
    delGroupTag(val, index2, index) {
      val.splice(index2, 1);
      if (val.length === 0) {
        this.groupTagsList.splice(index, 1);
      }
    },
    getStatus(row) {
      return (
        Array.isArray(row.tagItemIds) &&
        row.tagItemIds.length > 0 &&
        row.tagItemIds.some(
          (val) =>
            Array.isArray(val.checkedTagList) && val.checkedTagList.length > 0
        )
      );
    },
    removeDoc(row, index) {
      row.documentWareIds.splice(index, 1);
    },
    removeGroupDoc(index) {
      this.groupDocList.splice(index, 1);
    },
  },
  components: { PreviewItem, AddDoc, AddTag },
};
</script>
<style lang='scss' scoped>
.highlint-red {
  color: #fd5d5a;
}
.container {
  .choose-pic-type {
    margin-top: 40px;
    > span {
      font-weight: bold;
    }
    > span:nth-of-type(1) {
      margin-left: 10px;
    }
  }
}
.upload-image-single,
.upload-image-group {
  min-height: 100px;
  max-height: 600px;
  overflow: auto;
}
.tags-list {
  padding-top: 10px;
  position: relative;
  min-height: 30px;
  .tag-item {
    margin: 0 10px 5px 0;
    position: relative;
    cursor: default;
    .tag-list {
      position: relative;
      display: inline-block;
      margin: 0 10px 5px 0;
      .del-item {
        position: absolute;
        top: -30%;
        left: 90%;
        font-size: 12px;
        color: #ccc;
        transform: scale(0.75);
        cursor: pointer;
      }
    }
    &:hover {
      .del-item {
        display: inline-block;
      }
    }
  }
}
.add-icon {
  margin-top: 14px;
}
.doc-list {
  .doc-item {
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      padding: 10px;
      border-radius: 4px;
      background: #f4f1f4;
    }
  }
}
.button-list {
  margin-top: 20px;
  text-align: center;
}
</style>
