var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"append-to-body":"","width":"1200px","top":"2vh","visible":_vm.show,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"custom-title",attrs:{"slot":"title"},slot:"title"},[_vm._v("添加标签")]),(_vm.tagsList)?_c('div',{staticClass:"add-tag-container"},[_c('div',{staticClass:"container flex-row-start-start"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title flex-row-start-start"},[_c('el-checkbox',{on:{"change":_vm.handleCheckAll},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}}),_c('span',{staticClass:"margin-left-ten"},[_vm._v("选择标签")])],1),_c('div',{staticClass:"tag-container"},_vm._l((_vm.tagsList),function(group,Index){return _c('div',{key:group.id,staticClass:"tags"},[_c('el-collapse',[_c('el-collapse-item',[_c('template',{slot:"title"},[_c('el-checkbox',{nativeOn:{"click":function($event){return _vm.handleCheckBoxClick($event, group)}},model:{value:(group.checkStaus),callback:function ($$v) {_vm.$set(group, "checkStaus", $$v)},expression:"group.checkStaus"}},[_vm._v(_vm._s(group.tagGroupName)+_vm._s(("" + (_vm.getChooseNum(Index,group)))))])],1),_c('div',{staticClass:"tag-list"},[_c('c-input',{staticStyle:{"width":"200px","margin-bottom":"10px"},attrs:{"placeholder":"请输入标签名!"},model:{value:(_vm.searchList[Index]),callback:function ($$v) {_vm.$set(_vm.searchList, Index, $$v)},expression:"searchList[Index]"}}),_c('div',{staticClass:"flex-row-start-start-wrap"},[_vm._l((_vm.getTagItems(group, Index)),function(tag){return _c('span',{key:tag.id,class:{
                        'is-active': _vm.alreadyChooseTags.find(
                          function (item) { return item.groupId === group.id &&
                            item.checkedTagList.find(
                              function (item2) { return item2.id === tag.id; }
                            ); }
                        ),
                      },on:{"click":function($event){return _vm.chooseTag(group, tag, Index)}}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),_c('span',{staticStyle:{"height":"33px","padding":"0 15px","background":"#00bf8a","color":"#fff","border-color":"#00bf8a","cursor":"pointer"},on:{"click":function($event){return _vm.addTag(group)}}},[_c('i',{staticClass:"iconfont icon-xinzeng",staticStyle:{"line-height":"33px"}})])],2)],1)],2)],1)],1)}),0)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("已选择"),_c('span',{staticStyle:{"color":"#00bf8a"}},[_vm._v(_vm._s(_vm.getTotalNum()))]),_vm._v("个标签")])]),_c('div',{staticClass:"choose-tags"},_vm._l((_vm.alreadyChooseTags),function(item,index){return _c('div',{key:item.groupId,staticStyle:{"margin-bottom":"20px"}},[(item.checkedTagList.length > 0)?[_c('h3',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.groupName))]),_c('div',{staticClass:"flex-row-start-start-wrap",staticStyle:{"margin-top":"10px"}},_vm._l((item.checkedTagList),function(tag,index2){return _c('span',{key:tag.id,on:{"click":function($event){return _vm.dislodgeTagItem(tag, index, index2)}}},[_vm._v(_vm._s(tag.name))])}),0)]:_vm._e()],2)}),0)])]),_c('div',{staticClass:"button-list"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v("确定")]),_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }