<template>
  <el-dialog
    append-to-body
    width="1200px"
    v-dialogDrag
    top="2vh"
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="custom-title" slot="title">添加标签</div>
    <div class="add-tag-container" v-if="tagsList">
      <div class="container flex-row-start-start">
        <div class="left">
          <div class="title flex-row-start-start">
            <el-checkbox
              v-model="checkAll"
              @change="handleCheckAll"
            ></el-checkbox>
            <span class="margin-left-ten">选择标签</span>
            <!-- <span
              class="add-group-title"
              style="margin-left: auto; color: #00bf8a; cursor: pointer"
              @click="addTagGroup"
              >新增组</span
            > -->
          </div>
          <div class="tag-container">
            <div
              class="tags"
              v-for="(group, Index) in tagsList"
              :key="group.id"
            >
              <el-collapse>
                <el-collapse-item>
                  <template slot="title">
                    <el-checkbox
                      v-model="group.checkStaus"
                      @click.native="handleCheckBoxClick($event, group)"
                      >{{ group.tagGroupName }}{{`${getChooseNum(Index,group)}`}}</el-checkbox
                    >
                  </template>
                  <div class="tag-list">
                    <c-input
                      v-model="searchList[Index]"
                      style="width: 200px; margin-bottom: 10px"
                      placeholder="请输入标签名!"
                    ></c-input>
                    <div class="flex-row-start-start-wrap">
                      <span
                        @click="chooseTag(group, tag, Index)"
                        v-for="tag in getTagItems(group, Index)"
                        :key="tag.id"
                        :class="{
                          'is-active': alreadyChooseTags.find(
                            (item) =>
                              item.groupId === group.id &&
                              item.checkedTagList.find(
                                (item2) => item2.id === tag.id
                              )
                          ),
                        }"
                      >
                        {{ tag.name }}
                      </span>
                      <span
                        style="
                          height: 33px;
                          padding: 0;
                          padding: 0 15px;
                          background: #00bf8a;
                          color: #fff;
                          border-color: #00bf8a;
                          cursor: pointer;
                        "
                        @click="addTag(group)"
                      >
                        <i
                          class="iconfont icon-xinzeng"
                          style="line-height: 33px"
                        ></i>
                      </span>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">
            <span
              >已选择<span style="color: #00bf8a">{{ getTotalNum() }}</span
              >个标签</span
            >
          </div>
          <div class="choose-tags">
            <div
              v-for="(item, index) in alreadyChooseTags"
              :key="item.groupId"
              style="margin-bottom: 20px"
            >
              <template v-if="item.checkedTagList.length > 0">
                <h3 style="font-size: 18px">{{ item.groupName }}</h3>
                <div style="margin-top: 10px" class="flex-row-start-start-wrap">
                  <span
                    @click="dislodgeTagItem(tag, index, index2)"
                    v-for="(tag, index2) in item.checkedTagList"
                    :key="tag.id"
                    >{{ tag.name }}</span
                  >
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="button-list">
        <el-button type="primary" @click="handleOk">确定</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  addTagItem,
  addTagGroup,
  getTagItemList,
  getTagGroupList
} from '@/api/material.js'
import { deduplication, deduplicationProperty } from '@/assets/js/utils'
export default {
  props: ['show', 'info', 'curInfo'],
  data () {
    return {
      checkAll: false,
      showAddTagGroup: false,
      tagsList: [],
      alreadyChooseTags: [],
      searchList: []
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (!newV) {
          this.alreadyChooseTags = []
        } else {
          this.renderData()
        }
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    renderData () {
      const value = JSON.parse(JSON.stringify(this.info))
      this.searchList = Array.from({ length: value.length }, () => '')
      value.forEach((item) => {
        item.checkStaus = false
        item.halfCheckStatus = false
      })
      this.tagsList = value
      this.$nextTick(() => {
        if (this.curInfo.tagItemIds) {
          this.curInfo.tagItemIds.forEach((tag) => {
            // const targetIndex = this.alreadyChooseTags.findIndex(item => item.groupId === tag.tagGroupId)
            const targetIndex = this.alreadyChooseTags.findIndex(
              (item) => item.groupId === tag.groupId
            )
            if (targetIndex === -1) {
              this.alreadyChooseTags.push({
                groupId: tag.groupId,
                groupName: this.tagsList.find((item) => item.id === tag.groupId)
                  .tagGroupName,
                checkedTagList: tag.checkedTagList
              })
            } else {
              const { checkedTagList } = this.alreadyChooseTags[targetIndex]
              this.$set(this.alreadyChooseTags, targetIndex, {
                ...this.alreadyChooseTags[targetIndex],
                checkedTagList: deduplicationProperty([
                  ...checkedTagList,
                  tag.checkedTagList
                ])
              })
            }
          })
        }
      })
    },
    addTag (val) {
      const id = val.id
      this.$prompt('请输入标签名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        if (value === '') return this.$message.warning('名称不能为空!')
        addTagItem({
          tagItemName: value,
          tagGroupIds: [id]
        }).then(() => {
          this.$message.success('新增成功!')
          this.TagGroupList()
        })
      })
    },
    TagGroupList () {
      getTagGroupList({
        pageSize: 999
      }).then((res) => {
        res.list.forEach((item) => {
          item.checkStaus = false
          item.halfCheckStatus = false
        })
        this.tagsList = res.list
      })
    },
    addTagGroup () {
      this.$prompt('请输入标签组名!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        addTagGroup({
          tagGroupName: value,
          tagItemIds: null
        }).then(() => {
          this.$message.success('新增成功!')
          this.TagGroupList()
        })
      })
    },
    checkDom (e) {
      if (e.target === document.querySelector('.add-group-title')) return
      const dom = document.querySelector('.add-tag-group')
      if (dom && e.target !== dom && !dom.contains(e.target)) {
        this.showAddTagGroup = false
      }
    },
    handleOk () {
      if (this.getTotalNum() === 0) {
        return this.$message.warning('请选择标签!')
      }
      this.$emit('success', this.alreadyChooseTags)
      this.$emit('close')
    },
    handleCancel () {
      this.alreadyChooseTags = []
      this.$emit('close')
    },
    chooseTag (group, val, index) {
      const targetIndex = this.alreadyChooseTags.findIndex((item) => {
        return item.groupId === group.id
      })

      if (targetIndex === -1) {
        this.alreadyChooseTags.push({
          groupId: group.id,
          groupName: group.tagGroupName,
          checkedTagList: [
            {
              ...val,
              customName: `${group.tagGroupName}-${val.name}`
            }
          ]
        })
      } else {
        const { checkedTagList } = this.alreadyChooseTags[targetIndex]
        // ! 如果有相同的
        const Index = checkedTagList.findIndex((item) => item.id === val.id)
        if (Index > -1) {
          checkedTagList.splice(Index, 1)
          this.$set(this.alreadyChooseTags, targetIndex, {
            ...this.alreadyChooseTags[targetIndex],
            checkedTagList
          })
          return
        }
        this.$set(this.alreadyChooseTags, targetIndex, {
          ...this.alreadyChooseTags[targetIndex],
          checkedTagList: [
            ...checkedTagList,
            {
              ...val,
              customName: `${group.tagGroupName}-${val.name}`
            }
          ]
        })
      }
    },
    dislodgeTagItem (tag, index, subIndex) {
      this.alreadyChooseTags[index].checkedTagList.splice(subIndex, 1)
    },
    handleCheckAll () {
      if (this.checkAll) {
        this.alreadyChooseTags = []
        this.tagsList.forEach((item) => {
          if (Array.isArray(item.tagItems) && item.tagItems.length > 0) {
            this.alreadyChooseTags.push({
              groupId: item.id,
              groupName: item.tagGroupName,
              checkedTagList: item.tagItems
            })
          }
        })
      } else {
        this.alreadyChooseTags = []
      }
    },
    handleCheckBoxClick ($event, group) {
      if ($event.target.tagName !== 'INPUT') return
      this.$nextTick(() => {
        this.handleChange(group)
      })
    },
    handleChange (group) {
      // ! setTimeout 让 info.checkStatus拿到正确的值
      setTimeout(() => {
        const targetIndex = this.alreadyChooseTags.findIndex(
          (item) => item.groupId === group.id
        )
        if (group.checkStaus) {
          if (targetIndex > -1) {
            this.$set(this.alreadyChooseTags, targetIndex, {
              ...this.alreadyChooseTags[targetIndex],
              checkedTagList: group.tagItems
            })
          } else {
            this.alreadyChooseTags = [
              ...this.alreadyChooseTags,
              {
                groupId: group.id,
                groupName: group.tagGroupName,
                checkedTagList: group.tagItems
              }
            ]
          }
        } else {
          if (targetIndex > -1) {
            this.alreadyChooseTags.splice(targetIndex, 1)
          }
        }
      })
    },
    getTagItems (group, index) {
      const value = this.searchList[index]
      return value
        ? group.tagItems.filter((tag) => tag.name.includes(value))
        : group.tagItems
    },
    getTotalNum () {
      return this.alreadyChooseTags.reduce(
        (acc, b, c) => acc + b.checkedTagList.length,
        0
      )
    },
    getChooseNum (index, group) {
      const arr = this.alreadyChooseTags.find(item => item.groupId === group.id)?.checkedTagList
      return Array.isArray(arr) && arr.length > 0 ? ` (${arr.length}个)` : ''
    }
  },
  components: {}
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0;
}
.custom-title {
  font-size: 18px;
  padding: 20px;
  border-bottom: 2px solid #f2f2f2;
}
.add-tag-container {
  position: relative;
  .button-list {
    padding: 20px 0;
    border-top: 1px solid #f2f2f2;
    background: #fff;
    z-index: 999;
    text-align: center;
  }
  .add-tag-group {
    position: absolute;
    width: 300px;
    z-index: 999;
    border-radius: 8px;
    top: 14%;
    left: 56%;
    background: #fff;
    padding: 20px;
    border: 1px solid #f2f2f2;

    .tag-group-item {
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 10px;
      }
    }
  }
}
.container {
  height: 700px;
  overflow: auto;
  .left {
    width: 60%;
    height: 100%;
    overflow: auto;
    padding: 0 20px 25px 20px;
    border-right: 2px solid #f2f2f2;
    .title {
      position: sticky;
      top: 0;
      padding-top: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid #ebeef5;
      z-index: 9999;
      background: #fff;
    }
  }
  .right {
    padding: 25px 20px 0 20px;
    position: sticky;
    width: 500px;
    top: 0;
    height: 700px;
  }
}
.tag-container {
  .tags {
    margin-top: 15px;
    .el-collapse-item__header {
      height: 60px;
      line-height: 60px;
    }
  }
}
.title {
  font-size: 18px;
  font-weight: bold;
}

.tag-list {
  span {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    &:hover {
      color: #00bf8a;
      border-color: #00bf8a;
    }
  }
  .is-active {
    background: #00bf8a;
    color: #fff !important;
    border-color: #00bf8a;
  }
}
.choose-tags {
  margin-top: 20px;
  height: 635px;
  overflow: auto;
  span {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    color: #fff;
    background: #ccc;
    transition: all 0.3s;
    &:hover {
      background: #fd5d5a;
      border-color: #fd5d5a;
    }
  }
}
</style>
